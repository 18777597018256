.steperBorder {
  border: 2px solid rgb(241 90 41) !important;
  border-radius: 35px;
  padding: 5px;
}

.bar {
  padding: 8px 16px !important;
  border-radius: 335px;
  color: #fff !important;
  height: 2em;
  background: rgb(255, 204, 6);
  background: linear-gradient(
    90deg,
    rgba(255, 204, 6, 1) 0%,
    rgba(241, 96, 86, 1) 50%,
    rgba(237, 44, 126, 1) 100%
  );
}

.text {
    text-align: center;
}