.header-container {
    padding: 15px 0;
}

    .header-container.sm {
        padding: 10px 10px;
    }

.header-sm {
    display: inline-block;
    margin-bottom: 5px;
    color: #008cba;
}

    .header-sm.left {
        width: 60%;
        text-align: left;
    }

    .header-sm.right {
        width: 40%;
        text-align: right;
    }

#cfc-logo {
    width: 220px;
    height: auto;
    padding-right: 15px;
    margin-top: -8px;
}

#product-logo {
    width: 233px;
    padding-left: 15px;
    border-left: 0.15rem solid;
    border-image: linear-gradient( 0deg, rgba(0, 0, 0, 0.2), rgba(28, 56, 103, 0.9) 50%, rgba(0, 0, 0, 0.2) ) 1 100%;
}

#product-logo-sm {
    width: 200px;
}


.header-container ul {
    list-style-type: none;
    float: right;
    padding-left: 0;
}

    .header-container ul li {
        float: left;
    }

        .header-container ul li img {
            height: 50px;
            margin: 12px 15px 0 0;
        }

.header-text, .header-container a {
    color: #008cba;
}

.header-text {
    margin: 25px 15px 0 0;    
}

.linkText {
    color: #008cba;
    font-size: 14px;
}

.productLogoSm {
    width: 200px;
}

.mastercardImg {
    height: 53px;
    display: flex;
    margin: auto;
    width: 50px;
    object-fit: cover;
}

@media only screen and (max-width: 700px) {
    .linkText {
        font-size: 11px;
    }
}

@media only screen and (max-width: 426px) {
    .productLogoSm {
        width: 70% !important;
        height: 36px;
    }

    .mastercardImg {
        height: 36px;
    }
}

@media only screen and (max-width: 376px) {
    .productLogoSm {
        width: 80% !important;
    }

}
