.terms-header-title {
    padding: 15px 10px;
    color: #084B8A;
}

#applicant-disclosure h4.c1 {
    display: none;
}

#applicant-disclosure body.c10 {
    width: unset !important;
}


#applicant-disclosure table td p {
    word-break: break-word !important;
}

#e-sign #content-2 {
    display: flex;
}

.rounded-pill{
    line-height: 35px !important;
    border-radius: 48px !important;
}

#e-sign #content-2  div{
    width: 50%;
}

#e-sign #content-2  div ul {
    list-style-type: none;
}

#e-sign #content-2  div ul li {
    float: left;
    vertical-align:middle;
}

#e-sign #content-2  div ul li label {
    padding-left: 10px;
}


#e-sign p, 
#e-sign p ol li,
#applicant-disclosure p, 
#applicant-disclosure td, 
#applicant-disclosure li,
#e-sign #content-2  span,
#e-sign #content-2  div ul li .icon
{
    font-size: 16px !important;
}

input[type="checkbox"] {
    height:20px;
    width: 20px;
}

.terms-wrapper {
    background-color:#efefef;
    padding-top: 0;
}

#applicant-disclosure {
    height: 1500px;
    overflow-y: auto;
    padding-left: 0 !important;
}

.form-check-label {
    padding-left: 10px;
    padding-top: 5px;
}

.credit-protection-content ul li {
    padding-left: 10px;
}

.captcha-container {
    width: 100%;
    margin-top: 2em;
}

@media screen and (max-width: 576px) {
    .dm-form {
        padding-left: 10px;
        padding-right: 10px;
    }

    .terms-header-title {
        font-size: 24px;
        padding: 10px 15px 0 5px;
    }
    #e-sign #content-2 {
        display: block;
        padding-left: 0;
    }
    #e-sign #content-2  div{
        width: 100%;
    }
    #e-sign #content-2  span{
        font-size: 12px;
    }
    #e-sign #content-2  div ul {
        padding-inline-start: 0;
    }

    input[type="checkbox"] {
        height:13px;
        width: 13px;
    }

    #e-sign #content-2  div ul li span {
        font-size: 14px !important;
    }

    #applicant-disclosure table tr td:first-child {
        width: 35% !important;
    }

    .terms-acknowledgements
    {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }   

    #dm-submit-app {
        width: 150px !important;
        font-size: 16px;
    }

    .modal-footer-buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

/* overrides */

.terms-print {
    background-color: #efefef;
}
