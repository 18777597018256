.resultpage .row {
    margin-bottom: 40px ;
}

.resultpage .no-bottom-margin {
    margin-bottom: 0px !important ;
}

.font-115{
    font-size: 115%;
}
.resultpage h1 {
    font-size: 48px;
}

.gbCard{
    border-radius: 10px !important;
    box-shadow: rgb(0 0 0 / 30%) 4px 4px;
    background-color: rgba(246,247,252,1) !important;
}

.grandBrank p {
    font-weight: 600 !important;
    font-size: 21pt;
}

.grandBrank ul {
    list-style: none;
}


.GBQuestionMark{
    top: -10px;
    position: relative;
    margin-left: 1%;
}

@media screen and (min-width: 800px) {
    .clipboard-icon{
        float : right;
        width : 60%;
    }

    .check-icon{
        float : right;
        width : 50%;
    }
}

.gbresponse{
    color: #f56928 !important;
    border-radius: 10px;
    background-color: white;
    padding: 2%;
    border: 1px solid grey;
}

.resultpage h1, .resultpage h3, .resultpage h4 {
    color: #008cba;
}


.grandBankList{
    list-style: none;
}
.grandBankList li::before{
    content: "\2022";
    color: red;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -2em;
}

.grandBankList li{
    font-size: 150%;
}

.result-message {
    padding-top: 60px;
}

    .result-message.declined {
        padding-top: 40px;
        text-align: center;
    }

    .result-message.pendfee {
        padding-top: 20px;
    }

        .result-message.pendfee div {
            margin-bottom: 10px;
        }

.result-credit-limit {
    font-size: 32px !important;
}

.resultpage .reminder {
    border-radius: 10px;
    margin-top: 30px;
    padding: 20px;
    background-color: #f7faff;
}

    .resultpage .reminder small {
        font-size: 14px;
    }

    .resultpage .reminder li {
        margin-bottom: 10px;
    }

    .sublist li{
        margin-bottom: 0 !important;
    }

.mtop-20 {
    margin-top: 20px;
}

.remove-margin {
    margin: 0;
}

.decline-reason {
    color:#005dab;
    font-size: 21px;
}
