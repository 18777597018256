.radio-btn{
    display:flex;
    flex-flow: row wrap;
    align-items:center;
}
.radio-btn p {
    margin-bottom: 0px;
    margin-right: 5px;
}
.radio-btn div{
    margin-right: 1px;
}