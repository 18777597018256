.terms-wrapper {
    padding-top: 20px;
    padding-bottom: 30px;
}

.terms-section-wrapper {
    padding: 20px;
    border: 1px solid #eee;
}

.faArrowRight {
    color: #ffcc00;
    font-size: 36px;
    width: 50px;
}

#applicant-disclosure, #credit-protection-disclosures {
    height: 1500px;
    overflow-y: auto;
    padding-left: 10px;
}

#prequal-terms-container{
    padding-top : 0;
}

#prequal-terms-container h1,
#prequal-terms-container h2,
#prequal-terms-container h3,
#prequal-terms-container h4,
#prequal-terms-container h5,
#prequal-terms-container h6 {
    color: #008cba;
}

    .terms-table .terms-table-row:nth-child(even) {
        background-color: #f8f8f8;
    }

#prequal-terms-container .terms-header {
    background-color: #e5e5e5;
    color: #008cba;
    padding: 5px;
}
/* .input-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-items: flex-start;
}
    .input-container * {
        max-width: 90%;
    } */

.step-process {
    margin-bottom: 20px;
}

.step-process, .step-process .row {
    text-align: center !important;
}

.step-process a {
    font-size: 18px !important;
    color: #fff !important;
}

@media only screen and (max-width: 992px) {

    .faArrowRight {
        display: none;
    }
}

@media only screen and (max-width: 575.98px) {
    #btn-submit-application {
        font-size: 14px;
    }

    .color-prices {
        width: 120px;
    }
}
